<template>
  <div id="request-song" class="container">
    <div class="success" v-if="success">
      <p>
        We will review the information and you will receive an email with our
        decision
      </p>
      <p>
        If approved you will be able to license the song or recording at that
        time.
      </p>
      <md-button class="md-raised md-primary" @click="close">
        <md-icon>arrow_left</md-icon>
        Back to Song Search
      </md-button>
    </div>

    <form
      v-if="!success"
      novalidate
      @submit.prevent="requestSong"
      class="md-layout md-gutter"
    >
      <div class="md-layout-item md-size-100">
        <md-field
          class="md-layout"
          :class="{ 'md-invalid': errors.song_title }"
        >
          <label class="md-layout-item md-size-30 md-small-size-100"
            >Song Title*</label
          >
          <md-input
            class="md-layout-item md-size-70 md-small-size-100"
            v-model="song.song_title"
          ></md-input>
          <span class="md-error">{{ errors.song_title }}</span>
        </md-field>

        <md-field class="md-layout">
          <label class="md-layout-item md-size-30 md-small-size-100"
            >Songwriter(s)</label
          >
          <md-input
            class="md-layout-item md-size-70 md-small-size-100"
            v-model="song.writer"
          ></md-input>
        </md-field>

        <md-field class="md-layout">
          <label class="md-layout-item md-size-30 md-small-size-100"
            >Publisher name</label
          >
          <md-input
            class="md-layout-item md-size-70 md-small-size-100"
            v-model="song.publisher_name"
          ></md-input>
        </md-field>

        <md-field
          class="md-layout"
          :class="{ 'md-invalid': errors.popular_artist }"
        >
          <label class="md-layout-item md-size-30 md-small-size-100"
            >Popular Artist*</label
          >
          <md-input
            class="md-layout-item md-size-70 md-small-size-100"
            v-model="song.popular_artist"
          ></md-input>
          <span class="md-error">{{ errors.popular_artist }}</span>
        </md-field>

        <!-- <md-field class="md-layout">
          <label class="md-layout-item md-size-30 md-small-size-100"
            >Playing Minutes</label
          >
          <md-input
            class="md-layout-item md-size-70 md-small-size-100"
            v-model="song.playing_minutes"
          ></md-input>
        </md-field> -->

        <md-field class="md-layout">
          <label class="md-layout-item md-size-30 md-small-size-100"
            >Popular Album</label
          >
          <md-input
            class="md-layout-item md-size-70 md-small-size-100"
            v-model="song.album_title"
          ></md-input>
        </md-field>
      </div>

      <div class="md-layout-item md-size-100 buttons">
        <md-button type="submit" class="md-raised md-primary large-width"
          >Submit</md-button
        >
      </div>
    </form>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import axios from "axios";
import { apiUrl } from "@/services/api.js";

export default {
  name: "RequestSong",
  directives: {
    mask
  },
  data: function() {
    return {
      success: false,
      song: {
        song_title: "",
        popular_artist: "",
        playing_minutes: "",
        writer: "",
        album_title: "",
        track_title: "",
        publisher_name: "",
        type_of_license: "",
        release_date: "",
        label: "",
        playtime: ""
      },
      errors: {}
    };
  },
  methods: {
    close: function() {
      this.$emit("closeDialog");
    },
    requestSong: function() {
      this.errors = [];

      for (let field in this.song) {
        let value = this.song[field];

        if (field == "song_title" || field == "popular_artist") {
          if (value == undefined || value == "") {
            this.errors[field] = "Required";
          }
        }
      }

      if (Object.keys(this.errors).length == 0) {
        const params = {
          userid: this.$store.getters["auth/getUser"].user_id,
          song_title: this.song.song_title,
          artist: this.song.popular_artist,
          song_writers: this.song.writer,
          album_title: this.song.album_title,
          playing_minutes: this.song.playing_minutes,
          publisher_name: this.song.publisher_name
        };

        axios.post(`${apiUrl()}/songs/code_requests`, params).then(() => {
          this.$toastr("success", "Your request has been sent.");
          this.success = true;
          this.$emit("success");
        });
      } else {
        this.$toastr(
          "error",
          "You are missing required information. Please populate all fields highlighted red"
        );
      }
    }
  }
};
</script>

<style lang="scss">
#request-song {
  @media all and (min-width: 576px) {
    min-width: 700px;
    font-size: 16px;
  }

  .success {
    padding: 10px 0;
    text-align: center;
    font-size: 16px;

    p {
      margin: 40px 0;
    }
  }

  form {
    display: block;
    margin: 20px auto;

    .md-field {
      label {
        color: #000;
        font-weight: normal;
      }
      .md-input {
        border: 1px solid #ccc;
        width: 225px;
      }
    }
  }
}
</style>
