<template>
  <div id="search">
    <section class="section head">
      <div class="md-layout container">
        <form
          novalidate
          @submit.prevent="runSearch"
          class="md-layout-item md-size-50 md-small-size-100"
        >
          <h1 class="section-heading">SONG SEARCH</h1>

          <md-field class="md-layout">
            <label
              class="md-layout-item md-size-33 md-small-size-100"
              for="song-title"
            >
              song title
            </label>
            <md-input
              class="md-layout-item md-size-66 md-small-size-100"
              name="song-title"
              id="song-title"
              v-model="search.song_title"
              :disabled="searching"
            />
          </md-field>

          <md-field class="md-layout">
            <label
              class="md-layout-item md-size-33 md-small-size-100"
              for="artist"
            >
              artist
            </label>
            <md-input
              class="md-layout-item md-size-66 md-small-size-100"
              name="artist"
              id="artist"
              v-model="search.artist"
              :disabled="searching"
            />
          </md-field>

          <md-field class="md-layout">
            <label
              class="md-layout-item md-size-33 md-small-size-100"
              for="writer"
            >
              writer
            </label>
            <md-input
              class="md-layout-item md-size-66 md-small-size-100"
              name="writer"
              id="writer"
              v-model="search.writer"
              :disabled="searching"
            />
          </md-field>

          <div class="or">
            or search by
          </div>

          <md-field class="md-layout">
            <label
              class="md-layout-item md-size-33 md-small-size-100"
              for="song_code"
            >
              song code
            </label>
            <md-input
              class="md-layout-item md-size-66 md-small-size-100"
              name="song_code"
              id="song_code"
              v-model="search.song_code"
              :disabled="searching"
            />
          </md-field>

          <div class="buttons">
            <md-button class="md-dense" type="submit">
              Search
            </md-button>
            <a class="search-tips" @click="showDialog = true">Search Tips</a>
          </div>
        </form>

        <div class="md-layout-item md-size-15"></div>

        <div
          class="md-layout-item md-size-33 md-small-size-100 release-songs"
          v-if="releaseItems.length"
        >
          <div class="list-head">Songs in Release</div>
          <vue-custom-scrollbar
            class="scroll-area"
            :settings="{ wheelSpeed: 0.15 }"
          >
            <ul>
              <li v-for="(item, i) in releaseItems" :key="i">
                {{ item.songCode }} ({{ item.configs.join(", ") }})
              </li>
            </ul>
          </vue-custom-scrollbar>
        </div>
      </div>
    </section>

    <md-dialog :md-active.sync="showDialog" class="search-tips-dialog">
      <md-dialog-title>
        TIPS ON SEARCHING SONGFILE
        <md-button
          class="md-raised md-dense md-icon-button close"
          @click="showDialog = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>

      <md-dialog-content>
        <p>
          Songfile utilizes a "fuzzy logic" search engine to locate songs in the
          database. You do not need to use wildcards (*, ?) or boolean terms
          ("and", "or", etc).
        </p>

        <p>
          We suggest you search by song title first, then add songwriter name if
          necessary. Song search results are ranked in the order of song
          popularity to help you identify the right song.
        </p>

        <p>
          Using the name of the writer who is part of a group or uses an alias
          may not yield positive search results. For example, if a song was
          registered with HFA as being written by Sean Combs, a writer search
          using any of his aliases such as Puff Daddy or P. Diddy will not
          return that song.
        </p>

        <p>
          Note that the performer or artist that recorded the song may not be
          the songwriter. You may wish to research writer information at
          <a href="http://www.ascap.com" target="_blank">www.ascap.com</a>,
          <a href="http://www.bmi.com" target="_blank">www.bmi.com</a>, or
          <a href="http://www.sesac.com" target="_blank">www.sesac.com</a>
        </p>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary md-raised" @click="showDialog = false">
          <md-icon>arrow_left</md-icon>
          Back to Song Search
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import { mapGetters } from "vuex";

export default {
  name: "Search",
  components: {
    vueCustomScrollbar
  },
  props: ["release"],
  data: function() {
    return {
      showDialog: false,
      searching: false,
      search: {
        song_title: null,
        artist: null,
        writer: null,
        song_code: null
      }
    };
  },
  computed: {
    ...mapGetters("release", ["getAllCartItems"]),
    releaseItems: function() {
      if (this.release === undefined || this.release === null) {
        return [];
      }

      const items = [];

      for (const song of this.$store.getters["release/getAllCartItems"]) {
        if (song.releaseId != this.release.id) {
          continue;
        }

        const item = items.find(i => i.songCode == song.songCode);

        if (item) {
          item.configs.push(song.config);
        } else {
          items.push({
            songCode: song.songCode,
            configs: [song.config]
          });
        }
      }

      return items;
    }
  },
  methods: {
    runSearch: function() {
      let searchHash = new Object();
      if (
        this.search.song_title != null &&
        this.search.song_title.trim() != ""
      ) {
        searchHash.title = this.search.song_title;
      }
      if (this.search.artist != null && this.search.artist.trim() != "") {
        searchHash.artist = this.search.artist;
      }
      if (this.search.writer != null && this.search.writer.trim() != "") {
        searchHash.writer = this.search.writer;
      }
      if (this.search.song_code != null && this.search.song_code.trim() != "") {
        searchHash.song_code = this.search.song_code;
      }

      this.$store.dispatch("search/searchSongs", searchHash).then(() => {
        document.getElementById("search-results").scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      });
      this.$emit("runSearch");
    }
  },
  mounted() {
    this.$root.$on("updateRelease", this.runSearch);
  }
};
</script>

<style lang="scss">
#search {
  // padding: 50px 12.5vw;
  // margin: -1em -2em 75px;
  // color: #fff;
  // background-color: #4ac9a8;

  h1,
  h2,
  a {
    color: #fff !important;
  }

  h2 {
    font-weight: normal;
  }

  form {
    .md-field {
      align-items: center;

      label {
        color: #fff;
        font-size: 16px;
        text-transform: uppercase;
        font-family: $font-Gilroy-extrabold;
      }
      .md-input {
        border: 0;
      }
    }
    h1 + .md-field {
      margin-top: 40px;
    }
    .or {
      font-family: $font-Gilroy-extrabold;
      margin: 25px 0;
      display: block;
      text-align: center;
      font-size: 1.2em;
      // font-weight: bold;
      text-align: center;
    }
    .buttons {
      text-align: center;

      .md-button:not(:hover) {
        color: #fff;
      }
      .search-tips {
        font-weight: bold;
        text-decoration: underline;
        display: inline-block;
        padding: 10px 0;
      }
    }
  }
  .release-songs {
    margin-top: 20px;

    .list-head {
      font-family: $font-Gilroy-extrabold;
      font-size: 16px;
      background-color: $green;
      border-radius: 10px 10px 0 0;
      padding: 10px 25px;
      height: auto;
      max-height: 50px;
    }
    .scroll-area {
      background-color: transparent;
      height: 330px;
      background-color: rgba(0, 0, 0, 0.2);
      padding: 1em 25px;
      border-radius: 0 0 10px 10px;
      position: relative;

      .ps__thumb-y {
        background-color: $green;
        transition: top linear 0.1s;
      }
    }
    ul {
      list-style: none;
      margin: 0;

      li {
        color: #fff;
        font-size: 16px;
        padding: 0.5em 0 0;
        margin-bottom: 1em;
      }
    }
  }
}
.search-tips-dialog {
  .md-dialog-content {
    padding: 2em;
  }
  .md-dialog-actions {
    justify-content: center;
    padding-bottom: 2em;
  }
}
</style>
