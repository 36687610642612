<template>
  <div id="song-search">
    <Search @runSearch="showResults = true" :release="release" />

    <div class="progress-bar" v-if="status == 'running'">
      <md-progress-bar md-mode="indeterminate"></md-progress-bar>
    </div>

    <div id="search-results" class="results container" v-if="showResults">
      <p class="search-terms">
        Your search for "{{ yourSearch }}" returned {{ totalResults }} results.
      </p>

      <Results />
    </div>

    <transition name="fade">
      <md-button
        id="modify-search"
        class="md-primary md-raised md-dense"
        v-if="showModifyBtn"
        @click="toTop()"
      >
        Modify Search&nbsp;
        <md-icon>arrow_upward</md-icon>
      </md-button>
    </transition>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Search from "@/views/app/Search.vue";
import Results from "@/views/app/Results.vue";

export default {
  name: "SongSearch",
  components: {
    Search,
    Results
  },
  metaInfo: function() {
    return {
      title: " - Search for songs",
      meta: [
        {
          name: "description",
          content: "Search for songs and add them to a release."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      showResults: false,
      top: 0
    };
  },
  computed: {
    ...mapState("search", {
      status: state => state.status
    }),
    ...mapState("release", {
      release: state => state.current
    }),
    ...mapGetters("search", ["yourSearch", "totalResults"]),
    showModifyBtn: function() {
      return this.showResults && this.top >= 400;
    }
  },
  methods: {
    toTop: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      setTimeout(() => {
        document.getElementById("song-title").focus();
      }, 500);
    }
  },
  created: function() {
    window.addEventListener("scroll", () => {
      this.top = window.pageYOffset || document.documentElement.scrollTop;
    });

    if (this.release == null) {
      this.$store.dispatch("release/getLatestRelease").catch(() => {
        this.$toastr(
          "error",
          "You need to add a release before searching for songs."
        );
        this.$router.push({ name: "new-release" });
      });
    }
  }
};
</script>
<style lang="scss">
#song-search {
  #modify-search {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    box-shadow: 3px 3px 6px -4px #000;

    .md-icon {
      margin: 0 0 0 5px;
    }
  }
  .results {
    padding-top: 40px;

    .search-terms {
      margin: 0;
    }
  }
}
</style>
