<template>
  <div id="results" v-if="release != null">
    <div class="search-results">
      <div
        class="search-results__item"
        v-for="(item, index) in results"
        :key="index"
      >
        <div class="search-results-head">
          <div class="result-col">Song Code</div>
          <div class="result-col">Song Title</div>
          <div class="result-col">Songwriters</div>
          <div class="result-col result-group">
            <div class="result-group_title">Licensable on Songfile</div>
            <div
              class="result-col"
              :class="{ 'config-disabled': disabledConfig('physical') }"
            >
              Physical
            </div>
            <div
              class="result-col"
              :class="{ 'config-disabled': disabledConfig('digital') }"
            >
              Digital
            </div>
            <div
              class="result-col"
              :class="{ 'config-disabled': disabledConfig('ringtone') }"
            >
              Ringtone
            </div>
            <div
              class="result-col"
              :class="{ 'config-disabled': disabledConfig('streaming') }"
            >
              Streaming
            </div>
          </div>
          <div class="result-col">Add To Release</div>
        </div>

        <div class="search-results-body">
          <div class="result-col">
            <span class="result-col-label">Song Code</span>

            <a @click="openDetails(item)">{{ item.song_code }}</a>
          </div>

          <div class="result-col">
            <span class="result-col-label">Song Title</span>

            <a @click="openDetails(item)">{{ item.song_title }}</a>
          </div>

          <div class="result-col">
            <span class="result-col-label">Songwriters</span>

            {{ item.writer ? item.writer.replace(/,/g, ", ") : "-" }}
          </div>

          <div class="result-col result-group license-types">
            <div class="result-group_title">Licensable on Songfile</div>

            <div
              class="result-col"
              :class="{ 'config-disabled': disabledConfig('physical') }"
            >
              <span class="result-col-label">Physical</span>
              <md-icon :class="{ green: configLicensable(item, 'physical') }">
                {{
                  configLicensable(item, "physical") ? "check_circle" : "cancel"
                }}
              </md-icon>
            </div>

            <div
              class="result-col"
              :class="{ 'config-disabled': disabledConfig('digital') }"
            >
              <span class="result-col-label">Digital</span>
              <md-icon :class="{ green: configLicensable(item, 'digital') }">
                {{
                  configLicensable(item, "digital") ? "check_circle" : "cancel"
                }}
              </md-icon>
            </div>

            <div
              class="result-col"
              :class="{ 'config-disabled': disabledConfig('ringtone') }"
            >
              <span class="result-col-label">Ringtone</span>
              <md-icon :class="{ green: configLicensable(item, 'ringtone') }">
                {{
                  configLicensable(item, "ringtone") ? "check_circle" : "cancel"
                }}
              </md-icon>
            </div>

            <div
              class="result-col"
              :class="{ 'config-disabled': disabledConfig('streaming') }"
            >
              <span class="result-col-label">Streaming</span>
              <md-icon :class="{ green: configLicensable(item, 'streaming') }">
                {{
                  configLicensable(item, "streaming")
                    ? "check_circle"
                    : "cancel"
                }}
              </md-icon>
            </div>
          </div>

          <div class="result-col">
            <div
              @click="addToCart(item)"
              class="add-remove-release"
              v-if="!isItemOnRelease(item.song_code)"
              :class="{
                disabled: noConfig(item),
                'noi-help-btn': item.noiDisabled
              }"
            >
              <md-button class="md-raised md-primary md-dense md-icon-button">
                <md-icon>add</md-icon>
              </md-button>
              <span class="result-col-label">Add To Release</span>

              <md-tooltip md-direction="top" v-if="noConfig(item)">
                {{
                  item.noiDisabled
                    ? "Why can't I add this song to my release?"
                    : "This song is not available to add to your release"
                }}
              </md-tooltip>
            </div>

            <div class="add-remove-release" v-else>
              <md-icon>done</md-icon>
              <span class="result-col-label">Remove From Release</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pagination">
      <div class="result-buttons">
        <strong class="result-item">
          {{ parseInt(paginationHeaders("x-total")).toLocaleString() }} Results
        </strong>
        <md-button
          class="md-icon-button md-primary"
          :disabled="parseInt(paginationHeaders('x-page')) === 1"
          @click="previousPage"
        >
          <md-icon>keyboard_arrow_left</md-icon>
        </md-button>

        <md-button
          class="md-icon-button md-primary"
          @click="nextPage"
          :disabled="
            parseInt(paginationHeaders('x-page')) >=
              parseInt(paginationHeaders('x-total-pages'))
          "
        >
          <md-icon>keyboard_arrow_right</md-icon>
        </md-button>
      </div>
      <div class="last-result-item">
        <b>{{ paginationHeaders("x-page") }}</b> of
        <b>{{ parseInt(paginationHeaders("x-total-pages")) }}</b> Page(s)
      </div>
    </div>

    <div class="footer" id="results-footer">
      <div class="not-found">
        Song title not found?&nbsp;&nbsp;&nbsp;
        <a @click="showRequestSong = true">Click here.</a>
      </div>

      <div class="buttons" id="results-buttons">
        <md-button
          :to="{ name: 'my-cart' }"
          class="md-raised md-primary md-dense large-width"
        >
          Review Cart
        </md-button>

        <md-button
          @click="showReleaseDetails = true"
          class="md-raised md-primary md-dense large-width"
        >
          Release Details
        </md-button>
      </div>

      <div class="note">
        Please note that data contained in HFA's song database has been provided
        by HFA by publishers, licensees and others. HFA DOES NOT WARRANT THE
        ACCURACY OR COMPLETENESS OF THIS DATA. In some cases, copyright
        ownership information shown for a song may not reflect actual copyright
        ownership of a song, or may no longer be current. Songs listed in HFA's
        database may not be fully represented, or represented at all, by HFA,
        and may not be licensable through HFA.
      </div>
    </div>

    <!-- ------ DIALOGS ------ -->

    <md-dialog
      class="edit-release"
      :md-active.sync="showReleaseDetails"
      @md-closed="closeDialog"
    >
      <md-dialog-title>
        <span v-if="!editingRelease">RELEASE INFORMATION</span>
        <span v-if="editingRelease">EDIT RELEASE</span>

        <md-button
          class="md-raised md-dense md-icon-button close"
          @click="showReleaseDetails = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>

      <md-dialog-content>
        <ReleaseDetails
          @closeDialog="showReleaseDetails = false"
          @editRelease="editingRelease = true"
        />
      </md-dialog-content>
    </md-dialog>

    <md-dialog :md-active.sync="showSongDetails" @md-closed="closeDialog">
      <md-dialog-title>
        SONG INFORMATION

        <md-button
          class="md-raised md-dense md-icon-button close"
          @click="showSongDetails = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>

      <md-dialog-content>
        <SongDetails
          :release="release"
          :song="selectedSong"
          :noConfig="noConfig"
          @closeDialog="showSongDetails = false"
        />
      </md-dialog-content>
    </md-dialog>

    <md-dialog :md-active.sync="showLicenseRequest" @md-closed="closeDialog">
      <md-dialog-title>
        SONG CART
        <md-button
          class="md-raised md-dense md-icon-button close"
          @click="showLicenseRequest = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>

      <md-dialog-content>
        <LicenseRequest
          :release="release"
          :song="selectedSong"
          @closeDialog="showLicenseRequest = false"
        />
      </md-dialog-content>
    </md-dialog>

    <md-dialog :md-active.sync="showRequestSong">
      <md-dialog-title>
        <span v-if="!requestSuccess">ENTER DETAILS FOR REVIEW</span>
        <span v-if="requestSuccess">THANK YOU</span>

        <md-button
          class="md-raised md-dense md-icon-button close"
          @click="showRequestSong = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>

      <md-dialog-content>
        <RequestSong
          @closeDialog="showRequestSong = false"
          @success="requestSuccess = true"
        />
      </md-dialog-content>
    </md-dialog>

    <md-dialog :md-active.sync="showNoiHelp">
      <md-dialog-title>
        Why can't I add this song to my release?
        <md-button
          class="md-raised md-dense md-icon-button close"
          @click="showNoiHelp = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>

      <md-dialog-content>
        <div class="noi-help">
          <p>
            Distribution Date (Physical Product): You should enter the date you
            intend to begin selling or giving away to the public the product
            that includes the composition. The date must be five (5) or more
            days after the date of your order.
          </p>
          <p>
            Distribution Date (Digital Products): You should enter the date you
            intend to begin making available to the public digital copies of the
            sound recording that includes the composition. The date must be no
            more than twenty-four (24) days prior to the date of your order but
            can be any time after the date of the order.
          </p>
          <p>
            Manufacture Date (Physical Product): You should enter the date you
            have created copies of the product that includes the composition or
            the date you intend to begin making copies of the product that
            includes the composition. The date must be no more than twenty-four
            (24) days prior to the date of your order but can be any time after
            the date of the order.
          </p>
          <p>
            Record Company: If you intend to distribute, by way of permanent
            download or as a ringtone, copies of the sound recording embodying
            the composition, prior to completing your order please indicate in
            your Account Profile that you are a Record Company. A record company
            invests in, produces, and markets sound recordings of musical works,
            and distributes such recordings for remuneration through multiple
            sales channels.
          </p>
        </div>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ReleaseDetails from "@/views/app/ReleaseDetails.vue";
import SongDetails from "@/views/app/SongDetails.vue";
import LicenseRequest from "@/views/app/LicenseRequest.vue";
import RequestSong from "@/views/app/RequestSong.vue";

export default {
  name: "Results",
  components: {
    ReleaseDetails,
    SongDetails,
    RequestSong,
    LicenseRequest
  },
  data: function() {
    return {
      selectedSong: null,
      showReleaseDetails: false,
      showSongDetails: false,
      showLicenseRequest: false,
      showRequestSong: false,
      showNoiHelp: false,
      requestSuccess: false,
      editingRelease: false
    };
  },
  computed: {
    ...mapState({
      release: state => state.release.current,
      results: state => state.search.results.data,
      headers: state => state.search.results.headers,
      terms: state => state.search.results.terms
    }),
    ...mapGetters("release", ["isItemOnRelease"]),
    releaseDate: function() {
      if (this.release.release_date)
        return this.$moment(this.release.release_date, "YYYY-MM-DD");

      return null;
    },
    manufactureDate: function() {
      if (this.release.manufacturing_date)
        return this.$moment(this.release.manufacturing_date, "YYYY-MM-DD");

      return null;
    }
  },
  methods: {
    openDetails: function(song) {
      this.selectedSong = song;
      this.showSongDetails = true;
    },
    paginationHeaders: function(name) {
      if (this.headers !== undefined) {
        return this.headers[name];
      }
    },
    closeDialog: function() {
      this.selectedSong = null;
    },
    nextPage: function() {
      const nextNumber = { page: parseInt(this.headers["x-page"]) + 1 };
      const searchHash = Object.assign(this.terms, nextNumber);

      this.$store.dispatch("search/searchSongs", searchHash).then(() => {
        document.getElementById("search-results").scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      });
    },
    previousPage: function() {
      const previousNumber = { page: parseInt(this.headers["x-page"]) - 1 };
      const searchHash = Object.assign(this.terms, previousNumber);

      this.$store.dispatch("search/searchSongs", searchHash).then(() => {
        document.getElementById("search-results").scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      });
    },
    addToCart: function(item) {
      if (this.noConfig(item)) {
        if (item.noiDisabled) {
          this.showNoiHelp = true;
        }

        return false;
      }

      if (!this.isItemOnRelease(item.song_code)) {
        this.selectedSong = item;
        this.showLicenseRequest = true;
      } else {
        this.$toastr("error", "Item is already in the cart");
        return false;
      }
    },
    noConfig: function(item) {
      let noiDisabled = false;
      if (this.$store.getters["release/invalidNoiDigital"](item.noi_digital)) {
        item["digital"] = "N";
        noiDisabled = true;
      }

      if (
        this.$store.getters["release/invalidNoiPhysical"](item.noi_physical)
      ) {
        item["physical"] = "N";
        noiDisabled = true;
      }

      const configs = ["physical", "digital", "ringtone", "streaming"];
      let disabled = true;

      for (const config of configs) {
        if (item[config] == "Y" && !this.disabledConfig(config)) {
          disabled = false;
        }
      }

      item.noiDisabled = !disabled && noiDisabled;

      return noiDisabled || disabled;
    },
    disabledConfig: function(config) {
      return !this.release.selected_types.includes(config);
    },
    configLicensable: function(item, config) {
      if (
        config === "digital" &&
        item.noi_digital === "Y" &&
        !this.$store.getters["auth/isRecordCompany"]
      )
        return false;

      return item[config] == "Y";
    }
  },
  mounted: function() {
    console.log(this.release);

    if (window.innerWidth <= 600) {
      window.onscroll = function() {
        if (
          window.pageYOffset + window.innerHeight - 100 <
          document.getElementById("results-footer").offsetTop
        ) {
          if (
            !document
              .getElementById("results-buttons")
              .classList.contains("fixed-at-bottom")
          ) {
            document
              .getElementById("results-buttons")
              .classList.add("fixed-at-bottom");
            document
              .querySelectorAll("#results-buttons .md-button")
              .forEach(i => {
                i.classList.remove("md-primary", "md-raised");
              });
          }
        } else {
          if (
            document
              .getElementById("results-buttons")
              .classList.contains("fixed-at-bottom")
          ) {
            document
              .getElementById("results-buttons")
              .classList.remove("fixed-at-bottom");
            document
              .querySelectorAll("#results-buttons .md-button")
              .forEach(i => {
                i.classList.add("md-primary", "md-raised");
              });
          }
        }
      };
    }
  },
  beforeDestroy: function() {
    window.onscroll = null;
  }
};
</script>

<style lang="scss">
#results {
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3em;

    .result-buttons {
      display: flex;
      align-items: center;
    }
  }
  .type-of-license {
    display: flex;
    justify-content: flex-end;

    span {
      background-color: $green;
      display: block;
      width: 300px;
      // border-bottom: 1px solid #000;
      color: #fff;
      text-align: center;
      margin-right: 110px;
      padding: 5px 0;
      position: relative;
      top: 15px;
      font-weight: bold;
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .not-found,
    .buttons {
      padding: 1em 0;
      transition: all linear 100ms;
    }

    .not-found {
      display: flex;
      align-items: center;
    }

    .buttons > * {
      display: block;
      margin: 10px 0 0;
    }

    .note {
      width: 100vw;
      text-align: center;
      font-size: 0.9em;
      background-color: #f6f6f6;
      margin: 20px auto;
      width: 100%;
    }

    .buttons.fixed-at-bottom {
      position: fixed;
      bottom: 0;
      z-index: 99;
      background-color: $color-teal;
      width: 100%;
      margin: 0;
      flex-direction: row;
      justify-content: space-around;

      > .md-button {
        width: 40%;

        * {
          color: #fff;
        }
      }
    }

    @media all and (min-width: 576px) {
      justify-content: space-between;
      flex-direction: row;

      .buttons > * {
        display: inline-block;
        margin: 0 10px;
      }
      .note {
        width: 85%;
      }
    }
  }

  .search {
    &-head {
      .form {
        &-control {
          border-color: $color-white;
        }
      }
    }
    &-results {
      display: flex;
      flex-wrap: wrap;
      padding-top: 30px;
      margin-top: 30px;

      .result {
        &-col {
          word-break: break-word;
          flex-basis: 0;
          flex-grow: 1;
          padding: 10px 4px;
          font-family: $font-Gilroy-regular;
          font-weight: $fw-regular;
          font-size: 16px;
          letter-spacing: -0.03em;

          .add-remove-release {
            margin: 0 auto;
            display: flex;
            align-items: center;

            &.disabled {
              .md-button.md-primary.md-raised {
                background-color: gray;
                cursor: auto;

                &:hover:before {
                  background-color: gray;
                }
              }
            }
            &.noi-help-btn {
              cursor: help;

              * {
                cursor: help !important;
              }
            }
          }

          &-label {
            font-family: $font-Gilroy-extrabold;
            font-weight: $fw-extrabold;
            color: $color-black;
            font-size: 16px;
            display: none;
          }
          .btn {
            .result-col-label {
              margin: 0;
              min-width: 30px;
            }
          }
          &:first-child,
          &:last-child {
            max-width: 90px;
          }
          &:last-child {
            text-align: center;
          }

          &.config-disabled {
            position: relative;

            &:before {
              background-color: #fff;
              content: " ";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0.9;
            }
          }
        }

        &-group {
          display: flex;
          position: relative;
          padding: 0;
          background: $color-grey-4;
          width: 100%;
          max-width: 405px;
          flex: 2 0 auto;
          text-align: center;

          .result-col {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            max-width: none;
            flex-direction: column;
          }
          &_title {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            transform: translateY(-100%);
            font-family: $font-Gilroy-extrabold;
            font-weight: $fw-extrabold;
            background: $color-green;
            color: $color-white;
            text-align: center;
            padding: 5px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }

          &.license-types {
            .md-icon {
              color: $color-red;
              font-size: 35px !important;

              &.green {
                color: $color-green;
              }
            }
          }
        }
      }

      &-head,
      &-body {
        display: flex;
        width: 100%;

        & > .result-col {
          border-bottom: 1px solid $color-grey-3;

          &:nth-child(2) {
            flex-grow: 2;
          }
          &:nth-child(3) {
            flex-grow: 2;
          }
        }
      }
      &-body {
        .result-col:not(.result-group) {
          display: inline-flex;
          align-items: center;
        }
      }
      &-head {
        .result-col {
          font-family: $font-Gilroy-extrabold;
          font-weight: $fw-extrabold;
          color: $color-black;
        }
      }
      &__item {
        display: flex;
        flex-direction: column;
        width: 100%;

        [class*="-group_title"],
        [class*="-results-head"] {
          display: none;
        }

        &:first-child {
          [class*="-results-head"] {
            display: flex;
            [class*="-group_title"] {
              display: block;
            }
          }
        }
      }

      [class*="ico-"] {
        border-radius: 50%;
      }
      .ico {
        &-check {
          background: $color-green;
        }
        &-cross {
          background: $color-red;
        }
      }
    }

    @media all and (max-width: 991px) {
      &-head {
        .form-label {
          padding-top: 0;
        }
      }

      &-results {
        display: flex;
        flex-wrap: wrap;
        padding-top: 0;
        margin-top: 30px;
        margin-bottom: 30px;

        .result {
          &-col {
            width: 100%;
            flex-grow: 1;
            border: none;
            flex-basis: auto;

            &-label {
              display: inline-block;
              margin-right: 10px;
              min-width: 100px;
            }
            &:first-child,
            &:last-child {
              max-width: none;
            }
            &:last-child {
              text-align: center;
            }
          }

          &-group {
            flex-wrap: wrap;
            width: 100%;
            max-width: none;
            margin-top: 44px;
            margin-bottom: 10px;

            .result-col {
              flex-basis: 0;

              &-label {
                width: 100%;
                margin: 0 0 10px;
                text-align: center;
              }
            }
          }
        }
        &-body {
          display: flex;
          flex-wrap: wrap;
        }
        &__item {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #e0e0e0;

          .result-group_title {
            display: block;
          }

          &,
          &:first-child {
            margin-bottom: 0;
            .search-results-head {
              display: none;
            }
          }
        }
        .btn {
          &-plus {
            [class*="ico-"] {
              border-radius: 50%;
              background: $color-teal;
              margin-right: 10px;
            }
            &,
            &:hover {
              background: transparent;
            }

            &:hover {
              [class*="ico-"] {
                background: $color-teal-dark;
              }
            }
          }
        }
      }
    }

    @media all and (max-width: 575px) {
      &-results {
        .result {
          &-group {
            .result-col {
              flex-basis: 50%;
              width: 50%;
            }
          }
        }
      }
    }
  }
}

.noi-help {
  padding: 20px 0 0;
  p {
    color: #000;
    font-size: 1.2em;
    font-weight: 500;
  }
}
</style>
