<template>
  <div id="release-details" class="container">
    <div class="release-info" v-if="!editing && release">
      <div class="field">
        <div class="name">ALBUM OR SINGLE TITLE</div>
        <div class="value">{{ release.album }}</div>
      </div>

      <div class="field">
        <div class="name">EARLIEST EXPECTED DATE OF DISTRIBUTION</div>
        <div class="value">{{ release.release_date | moment("ll") }}</div>
      </div>

      <div class="field" v-if="release.manufacturing_date">
        <div class="name">MANUFACTURING DATE</div>
        <div class="value">{{ release.manufacturing_date | moment("ll") }}</div>
      </div>

      <div class="field">
        <div class="name">DISTRIBUTION</div>
        <div class="value">{{ release.distribution }}</div>
      </div>

      <div class="field">
        <div class="name">TYPE OF LICENSE</div>
        <div class="value">{{ release.selected_types.join(", ") }}</div>
      </div>

      <div class="field">
        <div class="name">AMOUNT OF RECORDING COPIES</div>
        <div class="value">{{ displayCopies() }}</div>
      </div>

      <div class="buttons">
        <md-button class="md-raised md-primary large-width" @click="close">
          <md-icon>arrow_left</md-icon>
          Back
        </md-button>

        <md-button
          class="md-raised md-primary large-width"
          @click="editRelease"
        >
          Edit
        </md-button>
      </div>
    </div>

    <NewRelease v-if="editing" :editing="true" @closeDialog="close()" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import NewRelease from "@/views/app/NewRelease.vue";

export default {
  name: "ReleaseDetails",
  components: {
    NewRelease
  },
  data: function() {
    return {
      editing: false,
      song: { releaseId: null }
    };
  },
  computed: {
    ...mapState("release", {
      release: state => state.current
    })
  },
  methods: {
    close: function() {
      this.$emit("closeDialog");
    },
    editRelease: function() {
      this.editing = true;
      this.$emit("editRelease");
    },
    displayCopies: function() {
      const text = [];
      for (let type in this.release.copies) {
        let copies = this.release.copies[type];

        for (let subtype in copies) {
          let value = copies[subtype];
          text.push(`${subtype} (${value})`);
        }
      }

      return text.join(", ");
    }
  }
};
</script>
<style lang="scss">
#release-details {
  @media all and (min-width: 576px) {
    min-width: 700px;
    font-size: 16px;
  }

  .release-info {
    padding: 40px 0 0;

    .field {
      margin: 20px 0;

      .name {
        font-family: $font-Gilroy-extrabold;
        font-size: 1em;
        margin-bottom: 10px;
      }
      .value {
        text-transform: capitalize;
        font-size: 1.5em;
      }
    }
  }
}
</style>
